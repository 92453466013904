import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
// import useAccountQuery from 'queries/useAccountQuery';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ResetPasswordForm from 'components/auth/ResetPasswordForm';
// import { signInWithFacebook, signInWithGoogle } from '@activebrands/core-web/state/auth/actions';
import SignInConsent from 'components/auth/SignInConsent';
import SignInForm from 'components/auth/SignInForm';
import SignUpFormStepOne from 'components/auth/SignUpFormStepOne';
import SignUpFormStepTwo from 'components/auth/SignUpFormStepTwo';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
// import ThemeButton from 'components/buttons/ThemeButton';
// import FacebookIcon from 'components/icons/FacebookIcon';
// import GoogleIcon from 'components/icons/GoogleIcon';
import Paragraph from 'components/text/Paragraph';
import { navigate } from 'gatsby';
import UpdatePasswordForm from './UpdatePasswordForm';

export const SIGN_IN = 'sign-in';
export const SIGN_IN_CONSENT = 'sign-in-consent';
export const SIGN_UP_STEP_ONE = 'sign-up-step-one';
export const SIGN_UP_STEP_TWO = 'sign-up-step-two';
export const RESET_PASSWORD = 'reset-password';
export const UPDATE_PASSWORD = 'update-password';

const forms = {
    [SIGN_IN]: SignInForm,
    [SIGN_IN_CONSENT]: SignInConsent,
    [SIGN_UP_STEP_ONE]: SignUpFormStepOne,
    [SIGN_UP_STEP_TWO]: SignUpFormStepTwo,
    [RESET_PASSWORD]: ResetPasswordForm,
    [UPDATE_PASSWORD]: UpdatePasswordForm,
};

const Wrapper = styled('div', {
    background: 'var(--color-bg-account)',
    position: 'relative',
    zIndex: 0,
    width: 'var(--width-overlays)',
    height: `calc(var(--vh) - var(--height-header))`,
    color: 'var(--color-text-account)',
});

//TODO: Add thirdparty sign in when its available without any security flaws
// const OAuthButton = styled(ThemeButton, {
//     width: '100%',
//     displat: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundColor: 'var(--color-base-error)',
// });

const UnauthenticatedOverlay = ({ form: formOverride, resetId, hash, redirectUri, redirect = true, ...rest }) => {
    const [form, setForm] = useState(formOverride || SIGN_IN);
    const [userEmail, setUserEmail] = useState({});

    const [css] = useStyletron();

    {
        /*TODO: Add thirdparty sign in when its available without any security flaws */
    }
    // const { signInThirdPartyServiceLabel } = useAccountQuery();

    const FormComponent = forms[form];

    {
        /*TODO: Add thirdparty sign in when its available without any security flaws */
    }
    // const handleOAuthSignIn = ({ target }) => {
    //     dispatch(signIn({ provider: target.value, type: isMobile ? 'redirect' : 'popup' }));
    // };

    // const handleOAuthLogin = async method => {
    //     switch (method) {
    //         case 'google':
    //             await dispatch(signInWithGoogle());
    //             redirectUri && navigate(redirectUri);
    //             break;
    //         case 'facebook':
    //             await dispatch(signInWithFacebook());
    //             redirectUri && navigate(redirectUri);
    //             break;
    //         default:
    //             throw `Unhandled method: ${method}`;
    //     }
    // };

    const handleMembership = isMember => {
        if (isMember) {
            if (redirect && redirectUri) {
                navigate(redirectUri);
            }
            overlay.closeAll();
        } else {
            setForm(SIGN_IN_CONSENT);
        }
    };

    return (
        <Wrapper>
            <ScrollableBox $style={{ height: '100%' }} scrolldirection="vertical">
                <div>
                    {[RESET_PASSWORD, UPDATE_PASSWORD, SIGN_UP_STEP_ONE, SIGN_UP_STEP_TWO].indexOf(form) === -1 && (
                        <>
                            <div
                                className={css({
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    marginBottom: '50px',
                                    padding: ['0 0 0 12px', null, null, null, null, '0 12px 0 24px'],
                                    borderBottom: '1px solid var(--color-border-account)',
                                })}
                            >
                                <Paragraph fontKeys="Misc/16_100_-02">{fm('Log in')}</Paragraph>
                                <ThemeButton
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        justifyContent: 'flex-end',
                                        padding: '12px',
                                    })}
                                    theme="link"
                                    onClick={() => overlay.close('unauthenticated')}
                                >
                                    <CloseIcon size="16px" padding="unset" />
                                </ThemeButton>
                            </div>

                            {/*TODO: Add thirdparty sign in when its available without any security flaws */}

                            {/* 
                                
                                <Paragraph $style={{ marginBottom: '12px' }} fontKeys="Miscellaneous/16_120_-1_500">
                                    {signInThirdPartyServiceLabel}
                                </Paragraph>
                                <div
                                    className={css({
                                        display: 'flex',
                                        gap: '8px',
                                        marginBottom: '24px',
                                    })}
                                >
                                    

                                    <OAuthButton
                                        $style={{
                                            backgroundColor: '#4964AF',
                                            color: '#FFFF',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                        theme={{ type: 'outlined', color: 'primary' }}
                                        value="facebook"
                                        onClick={() => handleOAuthLogin('facebook')}
                                    >
                                        <FacebookIcon />
                                        &nbsp;Facebook
                                    </OAuthButton>

                                    <OAuthButton
                                        $style={{ backgroundColor: '#fff', color: '#000' }}
                                        theme={{ color: 'primary' }}
                                        value="google"
                                        onClick={() => handleOAuthLogin('google')}
                                    >
                                        <GoogleIcon />
                                        &nbsp;Google
                                    </OAuthButton>
                                </div>
                                 */}

                            {form === SIGN_UP_STEP_ONE && (
                                <a
                                    className={css({
                                        textDecoration: 'underline',
                                        marginTop: '8px',
                                        padding: '0 24px',
                                    })}
                                    role="button"
                                    tabIndex="0"
                                    onClick={() => setForm('sign-in')}
                                >
                                    {fm('Back')}
                                </a>
                            )}
                        </>
                    )}
                    <FormComponent
                        $style={{ padding: ['0px 12px', null, null, null, null, '0px 24px'] }}
                        {...rest}
                        hash={hash}
                        id={resetId}
                        setForm={setForm}
                        setUserEmail={setUserEmail}
                        stepOneform={form}
                        userEmail={userEmail}
                        onSuccess={handleMembership}
                    />
                </div>
            </ScrollableBox>
        </Wrapper>
    );
};

UnauthenticatedOverlay.propTypes = {
    form: PropTypes.string,
    hash: PropTypes.string,
    redirect: PropTypes.bool,
    redirectUri: PropTypes.string,
    resetId: PropTypes.string,
};

export default UnauthenticatedOverlay;
