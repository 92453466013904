import { useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import useAccountQuery from 'queries/useAccountQuery';
import overlay from '@activebrands/core-web/libs/overlay';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { resetPassword } from '@activebrands/core-web/state/auth/actions';
import { useTypedDispatch } from '@activebrands/core-web/state/store';
import Form from 'components/Form';
import OverlayContent from 'components/Header/OverlayContent';
import ThemeButton from 'components/buttons/ThemeButton';
import ArrowIcon from 'components/icons/ArrowIcon';
import CloseIcon from 'components/icons/CloseIcon';
import { useStyletron } from 'styletron-react';
import ConfirmationMessage from './ConfirmationMessage';

const ResetPasswordForm = ({ setForm, forgottenPasswordText, infoText }) => {
    const dispatch = useTypedDispatch();
    const [css] = useStyletron();
    const { forgotPasswordContent, forgotPasswordConfirmationContent } = useAccountQuery();

    const [requestEmail, setRequestEmail] = useState(undefined);

    const [state, setState] = useState({
        email: undefined,
        errors: [],
        isLoading: false,
        isSubmitted: false,
    });

    const { isLoading, isSubmitted } = state;

    const onSubmit = async ({ email }) => {
        setState(prevState => ({ ...prevState, isLoading: true }));

        const response = await dispatch(resetPassword(email));

        if (response.message === 'Success') {
            setRequestEmail(email);
            setState(prevState => ({ ...prevState, email, isLoading: false, isSubmitted: true, errors: [] }));
        } else {
            setState(prevState => ({ ...prevState, errors: [response?.error], isLoading: false }));
        }
    };

    return isSubmitted ? (
        <div>
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    padding: '12px 24px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid var(--color-border-account)',
                })}
            >
                <ThemeButton
                    $style={{ display: 'flex', gap: '8px', padding: 0, alignItems: 'center' }}
                    disabled={isLoading}
                    size="md"
                    theme="link"
                    type="submit"
                    onClick={() => setForm('sign-in')}
                >
                    <ArrowIcon size="16px" $style={{ transform: 'rotate(180deg)' }} />
                    {fm('Back')}
                </ThemeButton>
                <ThemeButton
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                        padding: '0',
                    })}
                    theme="link"
                    onClick={() => overlay.close('unauthenticated')}
                >
                    <CloseIcon size="16px" state="reset" />
                </ThemeButton>
            </div>
            <ConfirmationMessage
                $style={{ padding: '50% 24px' }}
                content={forgotPasswordConfirmationContent}
                userEmail={requestEmail}
                buttonText={fm('Back to sign in')}
                heading={fm('One step left..')}
                onClick={() => setForm('sign-in')}
            />
        </div>
    ) : (
        <div>
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    padding: '12px 24px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid var(--color-border-account)',
                })}
            >
                <ThemeButton
                    $style={{ display: 'flex', gap: '8px', padding: 0, alignItems: 'center' }}
                    disabled={isLoading}
                    size="md"
                    theme="link"
                    type="submit"
                    onClick={() => setForm('sign-in')}
                >
                    <ArrowIcon size="16px" $style={{ transform: 'rotate(180deg)' }} />
                    {fm('Back')}
                </ThemeButton>
                <ThemeButton
                    className={css({
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                        padding: '0',
                    })}
                    theme="link"
                    onClick={() => overlay.close('unauthenticated')}
                >
                    <CloseIcon size="16px" state="reset" />
                </ThemeButton>
            </div>
            <div className={css({ padding: '50% 24px' })}>
                <OverlayContent content={forgotPasswordContent} />
                <Form
                    $style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                    }}
                    buttonLabel={isLoading ? `${fm('Loading')}...` : fm('Reset password')}
                    buttonProps={{
                        theme: 'defaultsecondary',
                        disabled: isLoading,
                        $style: { marginTop: '24px', marginBottom: '16px' },
                    }}
                    errors={state.errors}
                    fields={[
                        {
                            label: fm('Email'),
                            name: 'email',
                            required: true,
                            type: 'email',
                            $style: {
                                flexBasis: '100%',
                            },
                        },
                    ]}
                    onSubmit={onSubmit}
                />
                {forgottenPasswordText && (
                    <RichText data={forgottenPasswordText} tagComponents={{ paragraph: infoText }} />
                )}
            </div>
        </div>
    );
};

ResetPasswordForm.propTypes = {
    forgottenPasswordText: PropTypes.string,
    infoText: PropTypes.string,
    setForm: PropTypes.func,
};

export default ResetPasswordForm;
